



















































































import Vue from 'vue'
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ColorPallets from './ColorPallets.vue'
import CatalogCategoryPreview from './CatalogCategoryPreview.vue'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ModalEditCategory',

  components: {
    ValidatedBFormInput,
    ColorPallets,
    CatalogCategoryPreview
  },

  props: {
    catalog: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      image: null,
      imageUrl: null,
      loading: false,
      localCatalogCategory: {},
      colorPallets: [
        {
          hex: '#FEE8FA'
        },
        {
          hex: '#F0EDFB'
        },
        {
          hex: '#E8F7FE'
        },
        {
          hex: '#DDF4F4'
        },
        {
          hex: '#DDF4E1'
        },
        {
          hex: '#F0F5D2'
        },
        {
          hex: '#E5FEDC'
        },
        {
          hex: '#F0E1DF'
        },
        {
          hex: '#FCEDB9'
        },
        {
          hex: '#FCECE1'
        },
        {
          hex: '#FCF0E2'
        },
        {
          hex: '#F7F0EA'
        },
        {
          hex: '#F5F4F2'
        },
        {
          hex: '#EAEAEA'
        }
      ]
    }
  },

  watch: {
    editingCatalogCategory (val) {
      this.localCatalogCategory = val
    }
  },

  computed: {
    ...mapState('catalog', [
      'editingCatalogCategory'
    ])
  },

  created () {
    this.initCatalogCategory()
  },

  methods: {
    ...mapActions('catalog', [
      'updateCatalogCategory',
      'createCatalogCategory'
    ]),

    ...mapGetters('catalog', [
      'getNewCatalogCategory'
    ]),

    ...mapActions('common', [
      'uploadMedia'
    ]),

    ...mapMutations('catalog', [
      'setEditingCatalogCategory'
    ]),

    chooseFile () {
      this.$refs.file.click()
    },

    handleFileUpload () {
      this.image = this.$refs.file.files[0]
      this.imageUrl = URL.createObjectURL(this.image)
      this.localCatalogCategory.image_url = this.imageUrl
    },

    handleSelectColor (color: { hex: string }) {
      this.$set(this.localCatalogCategory, 'bg_color', color.hex)
    },

    initCatalogCategory () {
      this.localCatalogCategory = cloneDeep(this.editingCatalogCategory ?? this.getNewCatalogCategory())
    },

    onModalHidden () {
      this.reset()
    },

    reset () {
      this.localCatalogCategory = cloneDeep(this.getNewCatalogCategory())
      this.setEditingCatalogCategory = null
      this.image = null
      this.imageUrl = null
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      const actionName = this.localCatalogCategory.id ? 'updateCatalogCategory' : 'createCatalogCategory'

      if (this.image) {
        await this.handleUploadPhoto()
      }

      try {
        await this[actionName]({
          catalogId: this.$route.params.catalogId,
          categoryId: this.localCatalogCategory.id ?? null,
          data: {
            name: this.localCatalogCategory.name,
            bg_color: this.localCatalogCategory.bg_color,
            image_url: this.localCatalogCategory.image_url,
            is_hidden: this.localCatalogCategory.is_hidden
          }
        })
        this.$toast.success(this.localCatalogCategory.id ? 'Раздел успешно обновлён' : 'Раздел успешно создан')
        this.$bvModal.hide('modal-edit-catalog-category')
      } catch {
      }
    },

    async handleUploadPhoto () {
      const formData = new FormData()
      formData.append('file_to_upload', this.image)

      try {
        const res = await this.uploadMedia(formData)
        this.localCatalogCategory.image_url = res.url
      } catch {
        this.$toast.error('Произошла ошибка при загрузке фотографии')
      }
    }
  }
})
