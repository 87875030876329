




































import Vue, { PropType } from 'vue'
import ImageUploader from 'vue-image-upload-resize'

export default Vue.extend({
  name: 'Upload',

  components: {
    ImageUploader
  },

  props: {
    label: {
      type: String,
      default: () => 'Загрузить изображение'
    },

    value: {
      type: [Blob, String] as PropType<File | string | null>,
      default: null
    }
  },

  computed: {
    imageUrl () {
      if (typeof this.value === 'string') return this.value
      if (this.value instanceof Blob) return URL.createObjectURL(this.value)
      return null
    }
  },

  data () {
    return {
      image: null
    }
  },

  created () {
    if (this.imageUrl) {
      this.image = this.imageUrl
    }
  },

  methods: {
    handleRemoveImage () {
      this.image = null
      this.$emit('remove')
    }
  }
})
