












import { CatalogCategory } from '@/store/modules/catalog/types'
import Vue, { PropType } from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'

export default Vue.extend({
  name: 'CatalogChildCategoryCard',

  props: {
    childCategory: {
      type: Object as PropType<CatalogCategory>,
      default: () => {}
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'selectedCatalogChildCategory'
    ]),

    name () {
      return this.childCategory.name ?? 'Без названия'
    }
  },

  methods: {
    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapMutations('catalog', [
      'setSelectedCatalogChildCategory'
    ]),

    ...mapActions('catalog', [
      'deleteCatalogChildCategory'
    ]),

    handleSelectCatalogChildCategory () {
      this.setSelectedCatalogChildCategory(this.childCategory)
    }
  }
})
