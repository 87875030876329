











import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'EmptyMessage',

  props: {
    title: {
      type: String as PropType<string | null>,
      default: 'Добавить'
    },

    sm: {
      type: Boolean,
      default: () => false
    },

    lg: {
      type: Boolean,
      default: () => false
    }
  }
})
