










































































import Vue, { PropType } from 'vue'
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState
} from 'vuex'
import ConfirmProductDelete from './ConfirmProductDelete.vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import ProductForm from './ProductForm.vue'
import ModalEditProduct from './ModalEditProduct.vue'
import AddBlock from '@/components/AddBlock.vue'
import { Product } from '@/store/modules/catalog/types'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'CatalogProducts',

  components: {
    ConfirmProductDelete,
    SlickItem,
    SlickList,
    ProductForm,
    AddBlock,
    ModalEditProduct
  },

  props: {
    isEditable: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    isSearchActive: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },

  data () {
    return {
      isActionCopy: false,
      sortedItem: null,
      localProduct: null as Product,
      beforeOrderProducts: [] as Product[]
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalogChildCategoryProducts',
      'selectedCatalogChildCategory'
    ]),

    ...mapState('profiles', [
      'profile'
    ]),

    ...mapGetters('catalog', [
      'getNewProduct'
    ]),

    pickupDeliveryMethodIndex () {
      return this.localProduct?.delivery_methods?.findIndex(item => item.delivery_method === 'pickup')
    },

    deliveryDeliveryMethodIndex () {
      return this.localProduct?.delivery_methods?.findIndex(item => item.delivery_method === 'delivery')
    },

    profileCurrency () {
      return this.profile?.profile_data?.currency === 417 ? 'KGS' : '₽'
    }
  },

  watch: {
    selectedCatalogChildCategory (val) {
      if (!val.id) return

      this.setCatalogChildCategoryProducts([])
      this.fetchProducts()
    }
  },

  async created () {
    await this.fetchProducts()
  },

  methods: {
    ...mapActions('catalog', [
      'deleteProduct',
      'updateProduct',
      'updateProductsPosition',
      'getProductsByCategory'
    ]),

    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapMutations('catalog', [
      'setCatalogChildCategoryProducts',
      'sortProducts'
    ]),

    async fetchProducts () {
      try {
        this.setLoading(true)
        await this.getProductsByCategory({ categoryId: this.selectedCatalogChildCategory.id })
      } catch {
      } finally {
        this.setLoading(false)
      }
    },

    productIsHidden (product: Product) {
      return product.delivery_methods.every(item => item.is_hidden)
    },

    onSortStart (event: any) {
      this.sortedItem = this.catalogChildCategoryProducts[event.index]
      this.beforeOrderProducts = cloneDeep(this.catalogChildCategoryProducts)
    },

    async onSorted (newSorteredList: Product[]) {
      const sortedItemIndex = this.catalogChildCategoryProducts.findIndex((item: Product) => item.id === this.sortedItem.id)
      let newIndex = 0

      // Если сортируемый элемент не поставили в начало (0 index),
      // то задаем новый индекс
      if (newSorteredList[0].id !== this.sortedItem.id) {
        newIndex = newSorteredList.findIndex(item => item.id === this.catalogChildCategoryProducts[sortedItemIndex].id)
      }

      // Если новый индекс совпадает со старым, то ничего не делаем
      if (sortedItemIndex === newIndex) return

      const payload = {
        categoryId: this.sortedItem.category_id,
        newList: newSorteredList,
        productInsertId: this.sortedItem.id,
        productAfterInsertId: newIndex === 0 ? null : newSorteredList[newIndex - 1].id
      }
      this.sortProducts(payload)

      try {
        await this.updateProductsPosition(payload)
        this.beforeOrderProducts = cloneDeep(this.catalogChildCategoryProducts)
      } catch {
        this.$toast.error('Произошла ошибка при сортировке')
        this.sortProducts({ newList: this.beforeOrderProducts })
      }
    },

    async handleToggleDraft (product: Product) {
      try {
        this.loading = true
        await this.updateProduct({
          id: product.id,
          delivery_methods: [
            {
              delivery_method: 'delivery',
              discount: product?.delivery_methods[0]?.discount || 0,
              is_hidden: !product.delivery_methods[0].is_hidden,
              package_price: product.delivery_methods[0].package_price
            },
            {
              delivery_method: 'pickup',
              discount: product?.delivery_methods[1]?.discount || 0,
              is_hidden: !product?.delivery_methods[0]?.is_hidden,
              package_price: product.delivery_methods[1].package_price
            }
          ]
        })
        this.$bvModal.hide('modal-edit-product')
        this.$toast.success(!product.delivery_methods[0].is_hidden ? 'Продукт скрыт' : 'Продукт опубликован')
      } catch (error) {
        console.log(error)
        this.$toast.error('Произошла ошибка при изменении продукта')
      } finally {
        this.loading = false
      }
    },

    handleHideProduct (productId: string = null) {
    },

    handleAddProduct () {
      this.$bvModal.show('modal-edit-product')
    },

    toProductDetail (product: Product) {
      this.localProduct = product
      this.$bvModal.show('modal-edit-product')
    },

    async handleDeleteProduct (productId: string = null) {
      try {
        this.loading = true
        await this.deleteProduct(productId)
        this.$toast.success('Продукт удалён')
        await this.fetchProducts()
      } catch (error) {
        console.log('🚀handleDeleteProduct ~ error', error)
        this.$toast.error('Произошла ошибка при удалении продукта')
      } finally {
        this.loading = false
      }
    },

    onClickCopyProduct (product: Product) {
      this.isActionCopy = true
      this.localProduct = product
      this.$bvModal.show('modal-edit-product')
    }
  }
})
