











import Vue from 'vue'

export default Vue.extend({
  name: 'ColorPallets',

  props: {
    colors: {
      type: Array,
      default: () => []
    },

    currentColor: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      selectedColorIndex: null
    }
  },

  computed: {
    selectedColor () {
      if (this.selectedColorIndex === null) return null

      return this.colors[this.selectedColorIndex]
    }
  },

  created () {
    if (this.currentColor) {
      this.selectedColorIndex = this.colors.findIndex(item => item.hex.toLowerCase() === this.currentColor)
    }
  },

  methods: {
    selectColor (index: number) {
      this.selectedColorIndex = index
      this.$emit('select', this.selectedColor)
    }
  }
})
