




















import Vue from 'vue'

export default Vue.extend({
  name: 'ConfirmProductDelete',

  props: {
    target: {
      type: String,
      default: () => ''
    }
  }
})
