var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-edit-catalog","title-tag":"div","hide-footer":""},on:{"shown":_vm.onModalShown,"hidden":_vm.onModalHidden},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.localCatalog.id ? 'Изменить каталог' : 'Создать каталог'))])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ValidatedBFormInput',{attrs:{"label-cols-sm":6,"label":"Название каталога","rules":{ required: true, max: 26 }},model:{value:(_vm.localCatalog.name),callback:function ($$v) {_vm.$set(_vm.localCatalog, "name", $$v)},expression:"localCatalog.name"}}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Стоимость доставки","rules":{ required: true, min_value: 0, max: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":("Стоимость доставки, " + _vm.profileCurrency)}},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:({ currency: _vm.profileCurrency }),expression:"{ currency: profileCurrency }"}],attrs:{"id":"delivery_price","state":errors[0] ? false : null,"type":"text","aria-describedby":"delivery_price"},model:{value:(_vm.localCatalog.delivery_price),callback:function ($$v) {_vm.$set(_vm.localCatalog, "delivery_price", _vm._n($$v))},expression:"localCatalog.delivery_price"}}),_c('b-form-invalid-feedback',_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Сумма для бесплатной доставки","rules":{ required: true, min_value: 0, max: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":("Сумма для бесплатной доставки, " + _vm.profileCurrency)}},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:({ currency: _vm.profileCurrency }),expression:"{ currency: profileCurrency }"}],attrs:{"id":"order_price_to_free_delivery","state":errors[0] ? false : null,"type":"text","aria-describedby":"order_price_to_free_delivery"},model:{value:(_vm.localCatalog.order_price_to_free_delivery),callback:function ($$v) {_vm.$set(_vm.localCatalog, "order_price_to_free_delivery", _vm._n($$v))},expression:"localCatalog.order_price_to_free_delivery"}}),_c('b-form-invalid-feedback',_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-edit-catalog')}}},[_vm._v("Отменить")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.localCatalog.id ? 'Обновить' : 'Создать'))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }