

















import Vue from 'vue'

export default Vue.extend({
  name: 'Add',

  props: {
    btns: {
      type: Array,
      default: () => [{
        label: 'Добавить',
        action: 'add'
      }]
    }
  },

  data () {
    return {
      isOpen: false
    }
  }
})
