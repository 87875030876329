import { render, staticRenderFns } from "./AddBlock.vue?vue&type=template&id=1fa660a8&scoped=true&lang=pug&"
import script from "./AddBlock.vue?vue&type=script&lang=ts&"
export * from "./AddBlock.vue?vue&type=script&lang=ts&"
import style0 from "./AddBlock.vue?vue&type=style&index=0&id=1fa660a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa660a8",
  null
  
)

export default component.exports