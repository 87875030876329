var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.localProduct)?_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Фото товара","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localProductImage),expression:"localProductImage"}],staticClass:"d-none",domProps:{"value":(_vm.localProductImage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.localProductImage=$event.target.value}}}),_c('Upload',{attrs:{"label":"Добавить фото"},on:{"input":function($event){_vm.localProductImage = $event},"remove":function($event){_vm.localProductImage = null; _vm.localProduct.image_url = ''},"start-upload":function($event){_vm.loading = true},"end-upload":function($event){_vm.loading = false}},model:{value:(_vm.localProduct.image_url),callback:function ($$v) {_vm.$set(_vm.localProduct, "image_url", $$v)},expression:"localProduct.image_url"}}),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"photo-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Название товара","rules":{ required: true, max: 60 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label-for":"name","label":"Название товара"}},[_c('b-form-input',{attrs:{"id":"name","name":"name","type":"text","state":errors[0] ? false : null,"aria-describedby":"name-feedback"},model:{value:(_vm.localProduct.name),callback:function ($$v) {_vm.$set(_vm.localProduct, "name", $$v)},expression:"localProduct.name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"name-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Описание","rules":{ max: 300 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Описание"}},[_c('b-form-textarea',{staticClass:"product-form__description",attrs:{"type":"text"},model:{value:(_vm.localProduct.description),callback:function ($$v) {_vm.$set(_vm.localProduct, "description", $$v)},expression:"localProduct.description"}}),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"description-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Раздел"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Раздел"}},[_c('b-form-select',{attrs:{"options":_vm.catalogCategories,"text-field":"name","value-field":"id","state":errors[0] ? false : null},model:{value:(_vm.selectedLocalCategoryId),callback:function ($$v) {_vm.selectedLocalCategoryId=$$v},expression:"selectedLocalCategoryId"}}),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"category-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Категория товара","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [(!_vm.currentChildCategories.length)?[_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Категория товара"}},[_c('div',{staticClass:"form-control-plaintext text-muted"},[_vm._v("Нет созданных категорий")]),_c('b-form-input',{attrs:{"hidden":""},model:{value:(_vm.localProduct.category_id),callback:function ($$v) {_vm.$set(_vm.localProduct, "category_id", $$v)},expression:"localProduct.category_id"}}),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"category-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]:[_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Категория товара"}},[_c('b-form-select',{attrs:{"options":_vm.currentChildCategories,"text-field":"name","value-field":"id","state":errors[0] ? false : null},model:{value:(_vm.localProduct.category_id),callback:function ($$v) {_vm.$set(_vm.localProduct, "category_id", $$v)},expression:"localProduct.category_id"}}),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"category-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Объем/масса","rules":{ required: true, min_value: 1, max_value: 10000, integer: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Объем/масса"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"maxlength":"5","type":"text"},model:{value:(_vm.localProduct.attributes.value),callback:function ($$v) {_vm.$set(_vm.localProduct.attributes, "value", _vm._n($$v))},expression:"localProduct.attributes.value"}}),_c('b-form-select',{staticStyle:{"width":"76px","margin-left":".5rem"},attrs:{"value-field":"name","text-field":"name","options":_vm.weightVolumeValues},model:{value:(_vm.localProduct.attributes.unit),callback:function ($$v) {_vm.$set(_vm.localProduct.attributes, "unit", $$v)},expression:"localProduct.attributes.unit"}})],1),_c('b-form-invalid-feedback',{staticClass:"d-block",attrs:{"id":"volume-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Стоимость, KGS","rules":{ required: true, min_value: 1, max: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Стоимость, KGS"}},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:({ currency: _vm.profileCurrency }),expression:"{ currency: profileCurrency }"}],attrs:{"id":"price","state":errors[0] ? false : null,"type":"text","aria-describedby":"price-feedback"},model:{value:(_vm.localProduct.price),callback:function ($$v) {_vm.$set(_vm.localProduct, "price", _vm._n($$v))},expression:"localProduct.price"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Наличие","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Наличие в шт."}},[_c('b-form-input',{attrs:{"id":"stock","type":"text","state":errors[0] ? false : null,"aria-describedby":"stock-feedback"},model:{value:(_vm.localProduct.stock_qty),callback:function ($$v) {_vm.$set(_vm.localProduct, "stock_qty", _vm._n($$v))},expression:"localProduct.stock_qty"}}),_c('b-form-invalid-feedback',{attrs:{"id":"stock-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('hr'),_c('h3',[_vm._v("Способы доставки")]),_c('div',{staticClass:"delivery-method"},[_c('h4',[_vm._v("Доставка курьером")]),(_vm.deliveryEnabled && _vm.deliveryDeliveryMethodIndex > -1)?[_c('b-form-group',{attrs:{"label-cols":"6","label":"Скрыто"}},[_c('div',{staticClass:"pt-2 pb-2 text-right"},[_c('b-form-checkbox',{attrs:{"disabled":_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex].is_hidden,"name":"check-button","switch":""},model:{value:(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex].is_hidden),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex], "is_hidden", $$v)},expression:"localProduct.delivery_methods[deliveryDeliveryMethodIndex].is_hidden"}})],1)]),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Скидка","rules":{ min_value: 0, max_value: 99 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Скидка, %"}},[_c('b-form-input',{attrs:{"formatter":_vm.formatDiscountVal,"state":errors[0] ? false : null,"aria-describedby":"price-feedback","type":"number"},model:{value:(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex].discount),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex], "discount", _vm._n($$v))},expression:"localProduct.delivery_methods[deliveryDeliveryMethodIndex].discount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Стоимость упаковки, KGS*","rules":{ required: true, min_value: 0, max: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Стоимость упаковки, KGS*"}},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:({ currency: _vm.profileCurrency }),expression:"{ currency: profileCurrency }"}],attrs:{"id":"price","state":errors[0] ? false : null,"type":"text","aria-describedby":"price-box-feedback"},model:{value:(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex].package_price),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex], "package_price", _vm._n($$v))},expression:"localProduct.delivery_methods[deliveryDeliveryMethodIndex].package_price"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)})]:_vm._e()],2),_c('div',{staticClass:"delivery-method"},[_c('h4',[_vm._v("Самовывоз")]),(_vm.pickupEnabled && _vm.pickupDeliveryMethodIndex > -1)?[_c('b-form-group',{attrs:{"label-cols":"6","label":"Скрыто"}},[_c('div',{staticClass:"pt-2 pb-2 text-right"},[_c('b-form-checkbox',{attrs:{"disabled":_vm.localProduct.delivery_methods[_vm.deliveryDeliveryMethodIndex].is_hidden,"name":"check-button","false-value":true,"true-value":false,"switch":""},model:{value:(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex].is_hidden),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex], "is_hidden", $$v)},expression:"localProduct.delivery_methods[pickupDeliveryMethodIndex].is_hidden"}})],1)]),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Скидка","rules":{ min_value: 0, max_value: 99 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Скидка, %"}},[_c('b-form-input',{attrs:{"formatter":_vm.formatDiscountVal,"state":errors[0] ? false : null,"aria-describedby":"price-feedback","type":"number"},model:{value:(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex].discount),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex], "discount", _vm._n($$v))},expression:"localProduct.delivery_methods[pickupDeliveryMethodIndex].discount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Стоимость упаковки, KGS*","rules":{ required: true, min_value: 0, max: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Стоимость упаковки, KGS*"}},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:({ currency: _vm.profileCurrency }),expression:"{ currency: profileCurrency }"}],attrs:{"id":"price","state":errors[0] ? false : null,"type":"text","aria-describedby":"price-box-feedback"},model:{value:(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex].package_price),callback:function ($$v) {_vm.$set(_vm.localProduct.delivery_methods[_vm.pickupDeliveryMethodIndex], "package_price", _vm._n($$v))},expression:"localProduct.delivery_methods[pickupDeliveryMethodIndex].package_price"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)})]:_vm._e()],2)],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[(_vm.localProduct.id && !_vm.isActionCopy)?[_c('b-button',{attrs:{"id":"confirm-delete-modal-product","tabindex":"0","variant":"dark"}},[_vm._v("Удалить")]),_c('ConfirmProductDelete',{attrs:{"target":"confirm-delete-modal-product"},on:{"confirm":_vm.handleDeleteProduct}})]:[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-edit-product')}}},[_vm._v("Отмена")])],_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSubmit(false)}}},[_vm._v(_vm._s(_vm.localProduct.id || _vm.isActionCopy ? 'Сохранить' : 'Создать'))])],2)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }