import { render, staticRenderFns } from "./ModalEditChildCategories.vue?vue&type=template&id=a4c0ed60&scoped=true&lang=pug&"
import script from "./ModalEditChildCategories.vue?vue&type=script&lang=ts&"
export * from "./ModalEditChildCategories.vue?vue&type=script&lang=ts&"
import style0 from "./ModalEditChildCategories.vue?vue&type=style&index=0&id=a4c0ed60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c0ed60",
  null
  
)

export default component.exports