






































































































































































































































































































































































































































































































import Vue from 'vue'
import Upload from '@/components/Upload/index.vue'
import ConfirmProductDelete from './ConfirmProductDelete.vue'
import { TheMask, mask } from 'vue-the-mask'
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations
} from 'vuex'
import { cloneDeep } from '@/utils/functions'
import { Product } from '@/store/modules/catalog/types'
import { DELIVERY_METHODS } from '@/common/constants'

export default Vue.extend({
  name: 'ProductForm',

  components: {
    Upload,
    TheMask,
    ConfirmProductDelete
  },

  directives: {
    mask
  },

  props: {
    product: {
      type: Object,
      default: () => null,
      required: true
    },

    isActionCopy: {
      type: Boolean,
      default: false
    },

    isSearchActive: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      selectedLocalCategoryId: null,
      pickupEnabled: false,
      deliveryEnabled: false,
      localProduct: {} as Product,
      localProductImage: null,
      deliveryMethods: DELIVERY_METHODS,
      tokens: {
        F: {
          pattern: /^\d{1}$/
        },
        '#': {
          pattern: /\d/
        }
      },
      weightVolumeValues: [
        {
          name: 'кг'
        },
        {
          name: 'гр'
        },
        {
          name: 'л'
        },
        {
          name: 'мл'
        },
        {
          name: 'шт'
        },
        {
          name: 'комбо'
        },
        {
          name: 'порция'
        }
      ]
    }
  },

  watch: {
    deliveryEnabled (val) {
      this.toggleDeliveryMethod('delivery', val)
    },

    pickupEnabled (val) {
      this.toggleDeliveryMethod('pickup', val)
    }

    // currentChildCategories (val) {
    //   if (!val.length) this.localProduct.category_id = null
    // }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'catalogChildCategories',
      'catalogCategories',
      'selectedCatalogCategory',
      'categoriesHierarchy'
    ]),

    ...mapState('profiles', [
      'profile'
    ]),

    ...mapGetters('catalog', [
      'getNewProduct'
    ]),

    profileCurrency () {
      return this.profile?.profile_data?.currency === 417 ? 'KGS' : 'RUB'
    },

    currentChildCategories () {
      if (!this.categoriesHierarchy.length || !this.selectedLocalCategoryId) return []
      const category = this.categoriesHierarchy.find(item => item.id === this.selectedLocalCategoryId)
      return category.children
    },

    categories () {
      return cloneDeep(this.catalogChildCategories)
    },

    pickupDeliveryMethodIndex () {
      return this.localProduct.delivery_methods.findIndex(item => item.delivery_method === 'pickup')
    },

    deliveryDeliveryMethodIndex () {
      return this.localProduct.delivery_methods.findIndex(item => item.delivery_method === 'delivery')
    }
  },

  async created () {
    this.localProduct = cloneDeep(this.product)
    this.localProductImage = this.product.image_url
    this.pickupEnabled = this.isDeliverySet('pickup')
    this.deliveryEnabled = this.isDeliverySet('delivery')
    await this.fetchCategoriesHierarchy()
    this.selectedLocalCategoryId = this.selectedCatalogCategory.id ||
      this.categoriesHierarchy.find(c => c.children.some(ch => ch.id === this.product.category_id)).id
    if (!this.currentChildCategories.length) this.localProduct.category_id = null
  },

  methods: {
    ...mapActions('common', [
      'uploadMedia'
    ]),

    ...mapActions('catalog', [
      'createProduct',
      'updateProduct',
      'getProductsByCategory',
      'getCategoriesHierarchy'
    ]),

    ...mapMutations('catalog', [
      'setSelectedCatalogCategory',
      'setSelectedCatalogChildCategory'
    ]),

    toggleDeliveryMethod (deliveryType: string, enabled: boolean) {
      if (!enabled) {
        const index = this.localProduct.delivery_methods.findIndex(item => item.delivery_method === deliveryType)
        this.localProduct.delivery_methods.splice(index, 1)
      } else {
        const newProduct = cloneDeep(this.getNewProduct())
        const index = this.localProduct.delivery_methods.findIndex(item => item.delivery_method === deliveryType)

        if (index === -1) {
          const methodData = newProduct.delivery_methods.find(item => item.delivery_method === deliveryType)
          this.localProduct.delivery_methods.splice(this.localProduct.delivery_methods.length, 1, methodData)
        }
      }
    },

    isDeliverySet (type: string) {
      return this.localProduct.delivery_methods.filter(item => item.delivery_method === type).length > 0
    },

    formatDiscountVal (val: number) {
      return String(val).substring(0, 2)
    },

    handleDeleteProduct () {
      this.$bvModal.hide('modal-edit-product')
      this.$emit('delete-product', this.localProduct.id)
    },

    async fetchCategoriesHierarchy () {
      try {
        this.loading = true
        await this.getCategoriesHierarchy({
          catalogId: this.$route.params.catalogId
        })
      } catch {
      } finally {
        this.loading = false
      }
    },

    async handleUploadPhoto () {
      const formData = new FormData()
      formData.append('file_to_upload', this.localProductImage)

      try {
        const res = await this.uploadMedia(formData)
        this.localProduct.image_url = res.url
      } catch {
        if (!this.isActionCopy) {
          this.$toast.error('Произошла ошибка при загрузке фотографии')
        }
      }
    },

    async onSubmit (isDraft: boolean) {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      if (isDraft !== null) this.localProduct.is_hidden = isDraft

      if (this.localProduct.id && !this.isActionCopy) {
        await this.handleUpdateProduct()
      } else {
        await this.handleCreateProduct()
      }
      if (this.isSearchActive) {
        this.$emit('on-save-product-form')
        const selectedChildCat = this.currentChildCategories.find(c => c.id === this.localProduct.category_id)
        this.setSelectedCatalogCategory(this.categoriesHierarchy.find(c => c.children.some(ch => ch.id === this.localProduct.category_id)))
        this.setSelectedCatalogChildCategory(selectedChildCat)
        this.getProductsByCategory({ categoryId: selectedChildCat.id })
      }
    },

    async handleUpdateProduct () {
      try {
        this.loading = true

        if (this.product.image_url !== this.localProductImage) {
          await this.handleUploadPhoto()
        }

        await this.updateProduct(this.localProduct)
        this.$bvModal.hide('modal-edit-product')
        this.$toast.success('Продукт обновлён')
        this.$emit('fetch-products')
      } catch (error) {
        console.log(error)
        this.$toast.error('Произошла ошибка при изменении продукта')
      } finally {
        this.loading = false
      }
    },

    async handleCreateProduct () {
      try {
        this.loading = true
        await this.handleUploadPhoto()
        await this.createProduct({
          data: this.localProduct
        })

        this.$bvModal.hide('modal-edit-product')

        setTimeout(() => {
          this.$toast.success('Продукт добавлен')
        }, 100)
        this.$emit('fetch-products')

        this.$emit('product-added')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }
  }
})
