import { render, staticRenderFns } from "./CatalogProducts.vue?vue&type=template&id=6860377e&scoped=true&lang=pug&"
import script from "./CatalogProducts.vue?vue&type=script&lang=ts&"
export * from "./CatalogProducts.vue?vue&type=script&lang=ts&"
import style0 from "./CatalogProducts.vue?vue&type=style&index=0&id=6860377e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6860377e",
  null
  
)

export default component.exports