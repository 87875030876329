






















import Vue from 'vue'
import {
  mapActions,
  mapGetters
} from 'vuex'
import ProductForm from './ProductForm.vue'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ModalEditProduct',

  components: {
    ProductForm
  },

  props: {
    product: {
      type: Object,
      default: () => null
    },

    isActionCopy: {
      type: Boolean,
      default: false
    },

    isSearchActive: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      localProduct: null
    }
  },

  computed: {
    ...mapGetters('catalog', [
      'getNewProduct'
    ]),

    modalTitle () {
      if (this.isActionCopy) return 'Копировать товар'
      return this.product ? 'Изменить товар' : 'Создать товар'
    }
  },

  methods: {
    ...mapActions('catalog', [
      'deleteProduct'
    ]),

    initProduct () {
      this.localProduct = cloneDeep(this.product ?? this.getNewProduct())
    },

    onModalShown () {
      this.initProduct()
    },

    onModalHidden () {
      this.reset()
      this.$emit('hidden')
    },

    reset () {
      this.localProduct = null
    },

    async handleDeleteProduct (productId: string = null) {
      try {
        this.loading = true
        await this.deleteProduct({ catalogId: this.catalog.id, productId })
        this.$toast.success('Продукт удалён')
        this.$emit('fetch-products')
      } catch {
        this.$toast.error('Произошла ошибка при удалении продукта')
      } finally {
        this.loading = false
      }
    }
  }
})
