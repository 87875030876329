














import Vue from 'vue'

export default Vue.extend({
  name: 'CatalogCategoryPreview',

  props: {
    backgroundColor: {
      type: String,
      default: () => '#eee'
    },

    imageUrl: {
      type: String,
      default: () => null
    },

    title: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      selectedColorIndex: null
    }
  },

  computed: {
    selectedColor () {
      if (!this.selectedColorIndex) return null

      return this.colors[this.selectedColorIndex]
    }
  },

  methods: {
    selectColor (index: number) {
      this.selectedColorIndex = index
      this.$emit('select', this.selectedColor)
    }
  }
})
