



































































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import { CatalogChildCategory } from '@/store/modules/catalog/types'
import { SlickList, SlickItem } from 'vue-slicksort'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ModalEditChildCategories',

  components: {
    SlickList,
    SlickItem
  },

  props: {
    isEditable: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'selectedCatalogCategory',
      'catalogChildCategories'
    ]),

    renamedChildCategories () {
      return this.localChildCategories.filter((item: CatalogChildCategory) => {
        return this.catalogChildCategories.find(cat => cat.name !== item.name && cat.id === item.id)
      })
    }
  },

  data () {
    return {
      sortedItem: null,
      sortingClassname: 'sorting-items',
      localChildCategories: [] as CatalogChildCategory[],
      beforeOrderChildCategories: [] as CatalogChildCategory[]
    }
  },

  watch: {
    catalogChildCategories: {
      deep: true,
      handler (val) {
        this.localChildCategories = cloneDeep(val.slice(1, val.length))
      }
    }
  },

  methods: {
    ...mapActions('catalog', [
      'updateChildCategoriesPosition',
      'deleteCatalogChildCategory',
      'updateCatalogChildCategory'
    ]),

    ...mapMutations('catalog', [
      'sortChildCategories',
      'setEditingCatalogChildCategory'
    ]),

    shouldCancelStart (e) {
      return !e.target.classList.contains('child-categories__drag-icon')
    },

    onModalShown () {
      this.localChildCategories = cloneDeep(this.catalogChildCategories.slice(1, this.catalogChildCategories.length))
    },

    onModalHidden () {
      this.localChildCategories = []
    },

    handleEditChildCategory (childCategory: CatalogChildCategory) {
      this.setEditingCatalogChildCategory(childCategory)
      this.$bvModal.show('modal-edit-catalog-child-category')
    },

    handleAddChildCategory () {
      // this.$bvModal.hide('modal-edit-child-categories')
      this.$bvModal.show('modal-edit-catalog-child-category')
    },

    async handleSaveRenamedCategories () {
      if (!this.renamedChildCategories.length) return

      await Promise.all(this.renamedChildCategories.map(async (category: CatalogChildCategory) => {
        await this.renameChildCategory(category)
      }))
      this.$toast.success('Названия категорий обновлены')
    },

    async handleRemoveChildCategory (categoryId: string) {
      try {
        await this.deleteCatalogChildCategory({
          categoryId
        })
        this.$toast.success('Категория удалена')
      } catch (error) {
        console.log(error)
      }
    },

    async renameChildCategory (category: CatalogChildCategory) {
      try {
        await this.updateCatalogChildCategory({
          categoryId: this.selectedCatalogCategory.id,
          data: {
            id: category.id,
            name: category.name
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    onSortStart (event) {
      this.beforeOrderChildCategories = cloneDeep(this.catalogChildCategories)
      document.documentElement.classList.add(this.sortingClassname)
      this.sortedItem = this.localChildCategories[event.index]
    },

    onSortend () {
      document.documentElement.classList.remove(this.sortingClassname)
    },

    // onSorted (event) {
    // const sortedItemIndex = this.catalog.categories.findIndex(item => item.id === this.sortedItem.id)
    // const category = this.catalog.categories[sortedItemIndex]
    // let newIndex = null

    // if (event[0].id !== this.sortedItem.id) {
    //   newIndex = event.findIndex(item => item.id === category.id)
    // } else {
    //   newIndex = 0
    // }

    // this.updateCategoriesPosition({
    //   catalog_id: this.catalog.id,
    //   category_id_after_which_insert: newIndex === 0 ? null : this.catalog.categories[newIndex - 1].id,
    //   category_id_to_insert: category.id
    // })
    // },

    async onSorted (newSorteredList: CatalogChildCategory[]) {
      const sortedItemIndex = this.localChildCategories.findIndex((item: CatalogChildCategory) => item.id === this.sortedItem.id)
      let newIndex = 0

      // Если сортируемый элемент не поставили в начало (0 index),
      // то задаем новый индекс
      if (newSorteredList[0].id !== this.sortedItem.id) {
        newIndex = newSorteredList.findIndex(item => item.id === this.localChildCategories[sortedItemIndex].id)
      }

      // Если новый индекс совпадает со старым, то ничего не делаем
      if (sortedItemIndex === newIndex) return

      const payload = {
        newList: newSorteredList,
        categoryId: this.selectedCatalogCategory.id,
        categoryInsertId: this.sortedItem.id,
        categoryAfterInsertId: newIndex === 0 ? this.catalogChildCategories[0].id : newSorteredList[newIndex - 1].id
      }

      this.sortChildCategories({ newList: [this.catalogChildCategories[0], ...newSorteredList] })

      try {
        await this.updateChildCategoriesPosition(payload)
        this.beforeOrderChildCategories = cloneDeep(this.catalogChildCategories)
      } catch {
        this.$toast.error('Произошла ошибка при сортировке')
        // this.sortChildCategories({ newList: this.beforeOrderChildCategories })
      }
    }
  }
})
