















































import Vue from 'vue'
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState
} from 'vuex'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormSelect from '@/components/ui/ValidatedBFormSelect.vue'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ModalEditChildCategory',

  components: {
    ValidatedBFormInput,
    ValidatedBFormSelect
  },

  props: {
    childCategory: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      localCatalogChildCategory: {},
      categoryId: null,
      loading: false
    }
  },

  computed: {
    ...mapState('catalog', [
      'editingCatalogChildCategory',
      'selectedCatalogCategory'
    ]),

    ...mapState('catalog', [
      'catalogCategories'
    ])
  },

  methods: {
    ...mapActions('catalog', [
      'updateCatalogChildCategory',
      'createCatalogChildCategory'
    ]),

    ...mapMutations('catalog', [
      'setEditingCatalogChildCategory'
    ]),

    ...mapGetters('catalog', [
      'getNewCatalogChildCategory'
    ]),

    initCatalogChildCategory () {
      this.localCatalogChildCategory = cloneDeep(this.editingCatalogChildCategory ?? this.getNewCatalogChildCategory())
      if (this.selectedCatalogCategory) this.categoryId = this.selectedCatalogCategory.id
    },

    onModalShown () {
      this.initCatalogChildCategory()
    },

    onModalHidden () {
      this.reset()
      if (this.selectedCatalogCategory) this.categoryId = this.selectedCatalogCategory.id
    },

    reset () {
      this.localCatalogChildCategory = {}
      console.log(this.getNewCatalogChildCategory())
      this.setEditingCatalogChildCategory(this.getNewCatalogChildCategory())
      this.categoryId = this.selectedCatalogCategory.id
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      const actionName = this.localCatalogChildCategory.id ? 'updateCatalogChildCategory' : 'createCatalogChildCategory'
      try {
        await this[actionName]({
          categoryId: this.categoryId || this.selectedCatalogCategory.id,
          data: {
            id: this.localCatalogChildCategory?.id,
            name: this.localCatalogChildCategory.name,
            is_hidden: false
          }
        })
        this.$toast.success(this.localCatalogChildCategory.id ? 'Категория успешно обновлена' : 'Категория успешно создана')
        this.$bvModal.hide('modal-edit-catalog-child-category')
        // this.$bvModal.hide('modal-edit-child-categories')
      } catch (error) {
        console.log(error)
      }
    }
  }
})
