














































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import AddBlock from '@/components/AddBlock.vue'
import CatalogCategoryCard from './CatalogCategoryCard.vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import { CatalogCategory } from '@/store/modules/catalog/types'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'CatalogCategories',

  components: {
    SlickItem,
    SlickList,
    CatalogCategoryCard,
    AddBlock
  },

  props: {
    isEditable: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },

  data () {
    return {
      loading: false,
      beforeOrderCategories: [] as CatalogCategory[],
      localCategories: []
    }
  },

  watch: {
    catalogCategories (value) {
      this.localCategories = value.slice(1, value.length)
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'catalogCategories'
    ])
  },

  methods: {
    ...mapActions('catalog', [
      'updateCategoriesPosition',
      'getCatalogCategories'
    ]),

    ...mapMutations('catalog', [
      'sortCategories'
    ]),

    shouldCancelStart (e) {
      return !e.target.classList.contains('category-card__drag')
    },

    handleEditCategories () {
      this.$bvModal.show('modal-edit-categories')
    },

    onSortStart (event: any) {
      this.sortedItem = this.localCategories[event.index]
      this.beforeOrderCategories = cloneDeep(this.localCategories)
    },

    async onSorted (newSorteredList: CatalogCategory[]) {
      if (!this.isEditable) return this.$toast.error('К сожалению, на данный момент вы не можете регулировать порядок.')
      const sortedItemIndex = this.localCategories.findIndex((item: CatalogCategory) => item.id === this.sortedItem.id)
      let newIndex = 0

      // Если сортируемый элемент не поставили в начало (1 index),
      // то задаем новый индекс
      if (newSorteredList[0].id !== this.sortedItem.id) {
        newIndex = newSorteredList.findIndex(item => item.id === this.localCategories[sortedItemIndex].id)
      }

      // Если новый индекс совпадает со старым, то ничего не делаем
      if (sortedItemIndex === newIndex) return

      const payload = {
        newList: newSorteredList,
        catalogId: this.catalog.id,
        categoryInsertId: this.sortedItem.id, // category_id_to_insert
        categoryAfterInsertId: newIndex === 0 ? this.catalogCategories[0].id : newSorteredList[newIndex - 1].id // category_id_after_which_insert
      }

      this.sortCategories({ newList: [this.catalogCategories[0], ...newSorteredList] })

      try {
        await this.updateCategoriesPosition(payload)
        this.beforeOrderCategories = cloneDeep(this.localCategories)
        await this.getCatalogCategories({
          catalogId: this.$route.params.catalogId
        })
      } catch {
        this.$toast.error('Произошла ошибка при сортировке')
        // this.sortCategories({ newList: this.beforeOrderCategories })
      }
    }
  }
})
