


































import Vue, { PropType } from 'vue'
import {
  mapState
} from 'vuex'
import CatalogChildCategoryCard from './CatalogChildCategoryCard.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default Vue.extend({
  name: 'CatalogChildCategories',

  components: {
    CatalogChildCategoryCard,
    Swiper,
    SwiperSlide
  },

  props: {
    isEditable: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },

  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.child-categories__nav--next',
          prevEl: '.child-categories__nav--prev'
        }
      }
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalogChildCategories'
    ])
  },

  methods: {
    handleEditCategories () {
      this.$bvModal.show('modal-edit-child-categories')
    }
  }
})
