




































































import { CatalogCategory } from '@/store/modules/catalog/types'
import Vue, { PropType } from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'

export default Vue.extend({
  name: 'CatalogCategoryCard',

  props: {
    category: {
      type: Object as PropType<CatalogCategory>,
      default: () => {}
    },
    isEditable: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    isMain: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'selectedCatalogCategory'
    ]),

    name () {
      return this.category.name ?? 'Без названия'
    }
  },

  methods: {
    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapMutations('catalog', [
      'setSelectedCatalogCategory',
      'setEditingCatalogCategory',
      'setCatalogChildCategoryProducts',
      'setSelectedCatalogChildCategory'
    ]),

    ...mapActions('catalog', [
      'deleteCatalogCategory',
      'updateCatalogCategory'
    ]),

    handleSelectCatalogCategory () {
      this.setSelectedCatalogCategory(this.category)
      this.setCatalogChildCategoryProducts([])
      this.setSelectedCatalogChildCategory({})
    },

    async toggleVisibility (hideAction: boolean) {
      try {
        await this.updateCatalogCategory({
          catalogId: this.$route.params.catalogId,
          categoryId: this.category.id,
          data: {
            is_hidden: hideAction
          }
        })
        this.$toast.success(hideAction ? 'Раздел скрыт' : 'Раздел вновь отображается')
      } catch (error) {
        console.log(error)
      }
    },

    changeCatalogCategory () {
      this.setEditingCatalogCategory(this.category)
      this.$bvModal.show('modal-edit-catalog-category')
    },

    async handleDeleteCatalogCategory () {
      if (!await this.$bvModal.msgBoxConfirm('Это действие нельзя отменить', {
        titleHtml: `Вы уверены что хотите удалить <span class="text-primary">${(this.category.name && this.category.name !== '') ? this.category.name : 'раздел'}</span>?`,
        okVariant: 'secondary',
        modalClass: 'modal-confirm',
        okTitle: 'Удалить',
        cancelVariant: 'primary',
        cancelTitle: 'Отмена'
      })) {
        return
      }

      try {
        this.setLoading(true)
        await this.deleteCatalogCategory({
          categoryId: this.category.id
        })

        // Сбрасываем выделение выбранной категории
        this.resetSelectedCategory()

        this.$toast.success('Раздел удалён')
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    resetSelectedCategory () {
      this.setSelectedCatalogCategory({})
      this.setCatalogChildCategoryProducts([])
      this.setSelectedCatalogChildCategory({})
    }
  }
})
